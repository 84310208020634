import { Button } from "@9amhealth/shared";
import { useBloc as useBloc2 } from "@blac/react";
import styled from "@emotion/styled";
import NotificationSettingsBloc from "components/NotificationSettings/NotificationSettingsBloc";
import Translate from "components/Translate/Translate";
import type { FC } from "react";
import React from "react";
import { BlueCheckMark } from "src/constants/icons";
import { ONBOARDING_QUESTIONNAIRE_INITIAL_LAB } from "src/constants/misc";
import { isHybridApp } from "src/lib/platform";
import translate from "src/lib/translate";
import OnboardingBloc from "src/state/OnboardingBloc/OnboardingBloc";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { taskManagementState, useBloc } from "src/state/state";
import TaskManagementBloc, {
  TaskKey
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import CustomQuestionnaire from "components/CustomQuestionnaire/CustomQuestionnaire";
import CustomSuspense from "components/CustomSuspense/CustomSuspense";
import { CenterQuestionnaireContent } from "components/InAppQuestionnaire/InAppQuestionnaire";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import Track from "../../Track/Track";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--space-md);
  text-align: center;

  h1 {
    text-wrap: balance;
  }
`;

const InitialLabDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [, { handleInitialLabQuestionnaireCompleted, isTaskStatusFinal }] =
    useBloc2(OnboardingBloc);

  const [, { togglePushNotifications, pushNotificationsEnabled }] = useBloc(
    NotificationSettingsBloc,
    {
      create: () => new NotificationSettingsBloc()
    }
  );
  const [, { getTask }] = useBloc(TaskManagementBloc);

  const questionnaireTask = getTask(TaskKey.INITIAL_LAB_QUESTIONNAIRE);
  const completeLabsTask = getTask(TaskKey.COMPLETE_INITIAL_LAB_ORDER);
  const dataAvailable = isTaskStatusFinal(completeLabsTask);
  const showLabsQuestionnaire = !isTaskStatusFinal(questionnaireTask);
  const showFinalSuccess = !showLabsQuestionnaire;

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const onQuestionnaireSubmit = async (): Promise<boolean> => {
    void taskManagementState.loadProgramTasks(KnownProgram.ONBOARDING);
    return false;
  };

  const textContext = dataAvailable ? "recordsVerified" : "profileReviewed";
  const showPushNotificationButton = isHybridApp() && !pushNotificationsEnabled;

  return (
    <InDialog
      popup
      title={translate("task.yourInitialLab")}
      returnUrl={returnUrl}
      onClose={onClose}
    >
      {showLabsQuestionnaire && (
        <CenterQuestionnaireContent>
          <CustomSuspense>
            <CustomQuestionnaire
              resetScrollOnEachStep
              id={ONBOARDING_QUESTIONNAIRE_INITIAL_LAB}
              useRouting
              onDataSent={() => void handleInitialLabQuestionnaireCompleted()}
              onLastStepCompleted={() => void onQuestionnaireSubmit()}
            />
          </CustomSuspense>
          <Track event="Initial Labs Dialog Questionnaire Shown" />
        </CenterQuestionnaireContent>
      )}
      {showFinalSuccess && (
        <Container>
          <div style={{ position: "relative", display: "inline-block" }}>
            <img
              src="https://cdn.sanity.io/images/g38rxyoc/production/363592e8be393656ae36c2ae078cdcb795aaf5ae-512x430.png"
              alt="Clipboard with a checkmark"
            />
            <div style={{ position: "absolute", top: "12px", right: "75px" }}>
              {dataAvailable && <BlueCheckMark />}
            </div>
          </div>

          <h1 className="as-subhead2">
            <Translate
              msg="task.yourInitialLab.title"
              variables={{ context: textContext }}
            />
          </h1>
          <p className="as-little">
            <Translate
              msg="task.yourInitialLab.description"
              variables={{ context: textContext }}
            />
          </p>
          <Track
            event="Initial Labs Dialog Success Shown"
            data={{
              showPushNotificationButton
            }}
          />
          {showPushNotificationButton && (
            <Button
              style={{ marginTop: "0.5rem" }}
              theme="sunrise"
              onPress={() => togglePushNotifications(true)}
            >
              <Translate msg="notification.turnOn" />
            </Button>
          )}
        </Container>
      )}
    </InDialog>
  );
};

export default InitialLabDialog;
